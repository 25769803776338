import * as WebApi from 'OK/webapi';
import * as vanilla from "OK/utils/vanilla";
import * as ReactTagLoader from "OK/react-tag-loader";
import { THEME_CHANGE } from "OK/ThemeEventBuses";
import { Theme } from 'OK/ThemeEnum';

//  Temporary fix for cycle deps to avoid ssr error
interface IThemeChangeImpossibilityPopupNode extends HTMLElement {
    open: (theme?: Theme) => void;
    close: () => void
}

export { Theme };

export type IThemeResult = boolean;

export class ThemeChangeError extends Error {
    name = 'ThemeChangeError';

    constructor(e: Error) {
        super();
        this.stack = e.stack;
    }
}

export class IncompatibleBrowserError extends ThemeChangeError {
    message = 'Browser version does not support theme change';
}

export class ThemeSettingSaveError extends ThemeChangeError {
    message = 'Failed to save user theme settings on server';
}

export class ThemeSettingBlockUpdateError extends ThemeChangeError {
    message = 'Failed to update block containing theme setting';
}

const TOOLBAR_BLOCK_ID = 'ToolbarUserDropdown';
const USER_SETTINGS_BLOCK_ID = 'UserConfigMRB';
const USER_APPEARANCE_SETTINGS_BLOCK_ID = 'UserAppearanceSettings';

const THEME_CHANGE_IMPOSSIBILITY_POPUP_TAG_NAME = 'theme-change-impossibility-popup';

const DARK_THEME_MODIFIER = '__ui-theme_dark';
const LIGHT_THEME_MODIFIER = '__ui-theme_light';

let currentTheme: Theme | undefined;
let rootElement: HTMLElement = document.documentElement;
let media: MediaQueryList;

const getThemeChangePopupContainer = (): HTMLElement | null => {
    return document.getElementById('hook_Block_PopLayer');
};

export const browserCanUseCssVariables = (): boolean => {
    // @ts-ignore
    const supportsFn = (window.CSS && window.CSS.supports.bind(window.CSS)) || (window.supportsCSS);
    return !!supportsFn && supportsFn('color', 'var(--primary)');
};

export const browserCanUseSystemTheme = (): boolean => {
    return typeof window.matchMedia !== 'undefined' && window.matchMedia('(prefers-color-scheme)').matches;
};

export const isDarkSystemTheme = () => {
    if (browserCanUseSystemTheme()) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return true;
        }
    }
    return false;
};

export const getClientCurrentTheme = (): Theme => {
    if (rootElement.classList.contains(DARK_THEME_MODIFIER)) {
        return Theme.DARK;
    }
    if (rootElement.classList.contains(LIGHT_THEME_MODIFIER)) {
        return Theme.LIGHT;
    }
    return Theme.AUTO;
};

export const getThemeModifier = (theme: Theme): string => {
    if (theme === Theme.DARK) {
        return DARK_THEME_MODIFIER;
    }
    if (theme === Theme.LIGHT) {
        return LIGHT_THEME_MODIFIER;
    }
    return '';
};

const applyThemeWithModifier = (theme: Theme): void => {
    const newModifier: string = getThemeModifier(theme);

    rootElement.classList.remove(DARK_THEME_MODIFIER);
    rootElement.classList.remove(LIGHT_THEME_MODIFIER);
    if (newModifier) {
        rootElement.classList.add(newModifier);
    }
};

const changeThemeByMediaHandler = (ev: MediaQueryListEventMap['change']) => {
    THEME_CHANGE.emit({
        oldValue: currentTheme || Theme.LIGHT,
        newValue: ev.matches ? Theme.DARK : Theme.LIGHT,
    }, this || {});
}

export const subscribeChangeThemeByMedia = (theme: Theme) => {
    if (theme === Theme.AUTO && browserCanUseSystemTheme() && browserCanUseCssVariables()) {
        currentTheme = Theme.AUTO;
        media = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
        if (media?.addEventListener) {
            media?.addEventListener('change', changeThemeByMediaHandler);
        } else {
            media?.addListener(changeThemeByMediaHandler);
        }
    }
}

export const unsubscribeChangeThemeByMedia = () => {
    if (media?.removeEventListener) {
        media?.removeEventListener('change', changeThemeByMediaHandler);
    } else {
        media?.removeListener(changeThemeByMediaHandler);
    }
}

const requestThemeChange = (theme: Theme): Promise<IThemeResult | string> => {
    const oldTheme = currentTheme;
    currentTheme = theme;

    return WebApi
        .invoke('theme/update', {theme}).then((result) => {
            if (result) {
                THEME_CHANGE.emit({
                    oldValue: oldTheme || Theme.LIGHT,
                    newValue: theme,
                }, this || {});
            }
            return result;
        })
        .catch((e) => {
            throw new ThemeSettingSaveError(e);
        });
};

const applyTheme = (theme: Theme): Promise<void> => new Promise((resolve, reject) => {
    if (
        (!browserCanUseCssVariables() && theme !== Theme.LIGHT) ||
        (!browserCanUseSystemTheme() && theme === Theme.AUTO)
    ) {
        const container = getThemeChangePopupContainer();
        if (!container) {
            return;
        }
        return ReactTagLoader.getTag<IThemeChangeImpossibilityPopupNode>(
            THEME_CHANGE_IMPOSSIBILITY_POPUP_TAG_NAME, null, container
        )
            .then(node => node.open(theme))
            .then(() => reject(new IncompatibleBrowserError(new Error())));
    }

    if (currentTheme === Theme.AUTO) {
        unsubscribeChangeThemeByMedia();
    }

    subscribeChangeThemeByMedia(theme);

    applyThemeWithModifier(theme);

    return requestThemeChange(theme).then(() => resolve());
});

const refreshThemeSettingBlocks = (themeSettingBlocksUpdateTimeout?: number): Promise<void[]> => {
    return Promise.all(
        [TOOLBAR_BLOCK_ID, USER_SETTINGS_BLOCK_ID, USER_APPEARANCE_SETTINGS_BLOCK_ID].map(
            blockId => vanilla.ajax({ url: `/dk?cmd=${blockId}` })
                .then(blockMarkup => {
                    const updateBlock = () => blockMarkup && vanilla.updateBlockModelCallback(blockMarkup);

                    if (themeSettingBlocksUpdateTimeout) {
                        setTimeout(updateBlock, themeSettingBlocksUpdateTimeout);
                    } else {
                        updateBlock();
                    }
                })
        )
    )
        .catch((e) => {
            throw new ThemeSettingBlockUpdateError(e);
        });
};

export const changeTheme = (theme: Theme, themeSettingBlocksUpdateTimeout?: number): Promise<void> => {
    if (![Theme.AUTO, Theme.LIGHT, Theme.DARK].includes(theme) || theme === getClientCurrentTheme()) {
        return Promise.resolve();
    }

    return applyTheme(theme)
        .then(() => {
            refreshThemeSettingBlocks(themeSettingBlocksUpdateTimeout);
        })
        .catch((error: ThemeChangeError) => window.OK.logging.info(`${error.name}: ${error.message}`));
};

// Подписываемся на смену темы из системы, если выбрана auto
subscribeChangeThemeByMedia(getClientCurrentTheme());
